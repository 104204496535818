import * as React from 'react';

import RoadmapPage from '../../ui/pages/RoadmapPage';

// markup
const Page = () => {
  return <RoadmapPage />;
};

export default Page;
