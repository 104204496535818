import React from 'react';
import { Typography } from '@mui/material';
import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';

export default function RoadmapPage() {
  return (
    <>
      <Seo title='Roadmap' />
      <DefaultLayout>
        <Page pageTitle='Roadmap'>
          <Typography variant='h3' align='center' gutterBottom={true}>
            Coming Soon
          </Typography>
        </Page>
      </DefaultLayout>
    </>
  );
}
